import {
  Box,
  CircularProgress,
  InputBase,
  Snackbar,
  withStyles,
} from "@material-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSnackbar } from "./app/features/system/system_slice"

export const ElementOrLoader = (props) => {
  let elements;
  if (props === undefined || props.loading) {
    if (props.skeleton) {
      elements = props.skeleton;
    } else {
      elements = <Box style={{height:'100%', width: '100%', display:'flex',alignItems:'center', justifyContent:'center'}}><CircularProgress size={props.size? props.size:14} /></Box>;
    }
  } else {
    elements = props.children;
  }
  return elements;
}

export const SnackBarWrap = ({children}) => {
  const dispatch = useDispatch();
  const {snackbar} = useSelector(state => state.system);

  return (
    <React.Fragment>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        message={snackbar.text}
        onClose={()=>dispatch(setSnackbar({open:false, text:''}))}
      />
    </React.Fragment>
  )
}

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
