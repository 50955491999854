import React, { useEffect, useState } from "react"
import moment from 'moment';
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Button, Divider, TextField, Container, CssBaseline, Typography, Box, Paper,
  FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, InputLabel, Select, Input, Chip, MenuItem,
  makeStyles, Grid, Snackbar, Hidden, CircularProgress,
} from "@material-ui/core"
import axios from "axios"
import { API_ADDRESS, FRONTEND_URL } from "../lib/constants"

import {getAuthToken} from "../lib/auth";
import {ElementOrLoader} from '../components/util'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: theme.spacing(5)
  },
  shareLinkBox: {
    marginTop: theme.spacing(5)
  }
}));

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};


const WaitList = () => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [inviteCode, setInviteCode] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [waitlistDate, setWaitlistDate] = React.useState("")


  // Fetch data from the server. Setting deps to [] forces this to only run
  // once.

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.get(`${API_ADDRESS}/user/waitlist`, config)
      .then((response)=> {
        if (response.data.error) {
          return;
        }
        setData(response.data);
        setInviteCode(response.data['invite_code']);
        setWaitlistDate(moment(response.data["waitlist_date"]).format("MMM Do"));
      });
  },[]);

  const handleCopyButton = () => {
    copyToClipboard(`${FRONTEND_URL}/?code=${inviteCode}`);
    setSnackBarOpen(true);
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }


  return (
    <Layout>
      <SEO title="Greenwoodx is better with others." />
      <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Paper variant="outlined" elevation={0} className={classes.paper}>
                <Typography
                  align="center"
                  variant="h4"
                  gutterBottom>
                  <span role="img">🎉</span> Thanks for joining us on this journey<span role="img"> 👏🏿</span>
                </Typography>
                <Typography variant="subtitle1" >
                  Greenwoodx is currently invite-only so we'll add you to the waitlist and send you updates on your progress. You joined the waitlist on
                  <ElementOrLoader loading={waitlistDate === ""}>
                    <Typography color="primary" display="inline"> {waitlistDate}</Typography>.
                  </ElementOrLoader>
                  Don't worry, we'll keep your place in line.
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper variant="outlined" elevation={0} className={classes.paper}>
                <Typography
                  variant="h5"
                  gutterBottom>
                  Want to get access faster?
                </Typography>
                <Typography variant="body2" >
                  Have friends that could benefit from talking directly to professionals at Google, Facebook, and other top companies?
                  Send them the link below to have them join you. The more people you invite increases your position in line.
                </Typography>
                <Grid item container xs={12} className={classes.shareLinkBox}>
                  <Grid item xs={12} sm={5}>
                    <ElementOrLoader loading={inviteCode === ""}>
                      <Button variant="contained" color="primary" fullWidth onClick={handleCopyButton}>
                        Copy Invite Link
                      </Button>
                    </ElementOrLoader>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">
                    You've invited
                    <ElementOrLoader loading={inviteCode === ""}>
                      <Typography color="primary" display="inline" component="span"> {data['invited_count'] || 0}</Typography> {data['invited_count'] === 1? "person" : "people" } 😐
                    </ElementOrLoader>
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" elevation={0} className={classes.paper}>
                <Typography
                  variant="h5"
                  gutterBottom>
                  On your phone?
                </Typography>
                <Typography variant="body2" >
                  If the above button doesn't copy your link, it may be easier to just select and copy this:
                </Typography>

                <ElementOrLoader loading={inviteCode === ""}>
                  <Typography variant="body2" style={{marginTop: '20px'}} color="primary">
                    {FRONTEND_URL}/?code={data['invite_code']}
                  </Typography>
                </ElementOrLoader>
              </Paper>
            </Grid>
          </Grid>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        message="Special invite link copied!"
        />
    </Layout>
  );
}

export default WaitList;


